.ad-textbox {position: relative;}

.ad-textbox > .underline 
{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  /* background: #0fa5a2; */
  transform: scaleX(0);
  transition: 0.35s;
}

.ad-textbox > span, .ad-textbox > label 
{
  position: absolute;
  pointer-events: none;
  translate: 0 -50%;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.35s;
}

.ad-textbox > span 
{
  top: 50%;
  left: 0;
}

.ad-textbox > label 
{
  top: 50%;
  left: 34px;
  white-space: nowrap;
  font-family: "Euclid Circular A";
}

.ad-textbox > input, .ad-textbox > textarea 
{
  height: 60px;
  /* width: 220px; */
  padding-left: 34px;
  background: transparent;
  border: 0;
  outline: none;
  border-bottom: 2px solid #2e2a33;
  /* color: rgba(255, 255, 255, 0.96); */
  font-family: "Euclid Circular A";
  font-size: 16px;
}

.ad-textbox > :is(input:focus, input.has-value) ~ span,  .ad-textbox > :is(textarea:focus, textarea.has-value) ~ span{color: rgba(255, 255, 255, 0.96);}

.ad-textbox > :is(input:focus, input.has-value) ~ label, .ad-textbox > :is(textarea:focus, textarea.has-value) ~ label {color: #0fa5a2;}

.ad-textbox > :is(input:focus, input.has-value) ~ label, .ad-textbox > :is(textarea:focus, textarea.has-value) ~ label 
{
  translate: -42px -44px;
  scale: 0.875;
}

.ad-textbox > :is(input:focus, input.has-value) ~ .underline, .ad-textbox > :is(textarea:focus, textarea.has-value) ~ .underline {transform: scaleX(1);}

.md-textbox 
{
  position: relative;
  font-family: "Euclid Circular A";
}

.md-textbox :is(label, span) 
{
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  pointer-events: none;
  color: #888888;
  transition: 0.3s;
}

.md-textbox > label 
{
  left: 10px;
  translate: 0 -50%;
  padding: 4px 8px;
}

.md-textbox > span 
{
  right: 16px;
  font-size: 28px;
}

.md-textbox > input, .md-textbox > textarea 
{
  height: 56px;
  /* width: 260px; */
  padding-left: 16px;
  border: 2px solid #888888;
  border-radius: 8px;
  outline: none;
  background: transparent;
  /* color: #f9f9f9; */
  font-family: inherit;
  font-size: 16px;
}

.md-textbox > :is(input:focus, .has-value), .md-textbox > :is(textarea:focus, .has-value){border-color: #8f44fd;}

.md-textbox > :is(input:focus, .has-value) ~ span, .md-textbox > :is(textarea:focus, .has-value) ~ span{color: #f9f9f9;}

.md-textbox > :is(input:focus, .has-value) ~ label, .md-textbox > :is(textarea:focus, .has-value) ~ label 
{
  background: #151515;
  color: rgba(255, 255, 255, 0.75);
  translate: 0 -46px;
}
.ad-textbox {position: relative;}

.ad-textbox > .underline 
{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  /* background: #0fa5a2; */
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: 0.35s;
}

.ad-textbox > span, .ad-textbox > label 
{
  position: absolute;
  pointer-events: none;
  translate: 0 -50%;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.35s;
}

.ad-textbox > span 
{
  top: 50%;
  left: 0;
}

.ad-textbox > label 
{
  top: 50%;
  left: 34px;
  white-space: nowrap;
  font-family: "Euclid Circular A";
}

.ad-textbox > input, .ad-textbox > textarea 
{
  height: 60px;
  /* width: 220px; */
  padding-left: 34px;
  background: transparent;
  border: 0;
  outline: none;
  border-bottom: 2px solid #2e2a33;
  /* color: rgba(255, 255, 255, 0.96); */
  font-family: "Euclid Circular A";
  font-size: 16px;
}

.ad-textbox > :is(input:focus, input.has-value) ~ span,  .ad-textbox > :is(textarea:focus, textarea.has-value) ~ span{color: rgba(255, 255, 255, 0.96);}

.ad-textbox > :is(input:focus, input.has-value) ~ label, .ad-textbox > :is(textarea:focus, textarea.has-value) ~ label {color: #0fa5a2;}

.ad-textbox > :is(input:focus, input.has-value) ~ label, .ad-textbox > :is(textarea:focus, textarea.has-value) ~ label 
{
  translate: -42px -44px;
  scale: 0.875;
}

.ad-textbox > :is(input:focus, input.has-value) ~ .underline, .ad-textbox > :is(textarea:focus, textarea.has-value) ~ .underline {-webkit-transform: scaleX(1);transform: scaleX(1);}

.md-textbox 
{
  position: relative;
  font-family: "Euclid Circular A";
}

.md-textbox :is(label, span) 
{
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  pointer-events: none;
  color: #888888;
  transition: 0.3s;
}

.md-textbox > label 
{
  left: 10px;
  translate: 0 -50%;
  padding: 4px 8px;
}

.md-textbox > span 
{
  right: 16px;
  font-size: 28px;
}

.md-textbox > input, .md-textbox > textarea 
{
  height: 56px;
  /* width: 260px; */
  padding-left: 16px;
  border: 2px solid #888888;
  border-radius: 8px;
  outline: none;
  background: transparent;
  /* color: #f9f9f9; */
  font-family: inherit;
  font-size: 16px;
}

.md-textbox > :is(input:focus, .has-value), .md-textbox > :is(textarea:focus, .has-value){border-color: #8f44fd;}

.md-textbox > :is(input:focus, .has-value) ~ span, .md-textbox > :is(textarea:focus, .has-value) ~ span{color: #f9f9f9;}

.md-textbox > :is(input:focus, .has-value) ~ label, .md-textbox > :is(textarea:focus, .has-value) ~ label 
{
  background: #151515;
  color: rgba(255, 255, 255, 0.75);
  translate: 0 -46px;
}
a:link,a:visited{text-decoration: none;}

footer
{
    text-align: center;
    font-size: .75rem;
}

img
{
    width: 150px;
    height: 200px;
    text-align: center;
}

/* body
{
  font-family: Arial, Helvetica, sans-serif;
  background-color: #1C2A44;
} */

header{height: 110vh;}

td input, td textarea{width: 100%;}

nav{height: 35px;}

.roundedCorners{border-radius: 2em;}

/*id selector styles*/

/* #menuitem0
{
    font-size: 24px;
} */

#contactTable
{
    margin-left: auto;
    margin-right: auto;
}

#divAboutDetails td
{
    border-style: solid;
    border-width: thin;
    width: auto;
}

#divMenuItems
{
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border: 0.1px solid #099FFF;
  background-color: #18181b;
  box-shadow: 0 4px 8px 0 #00FFFF, 0 6px 20px 0 rgba(0,0,0,0.19);
}

#divMobileMenuItems
{
  /* display: flex;
  flex-direction: row;
  padding: 10px 0; */
  border: 0.1px solid #099FFF;
  background-color: #18181b;
  box-shadow: 0 4px 8px 0 #00FFFF, 0 6px 20px 0 rgba(0,0,0,0.19);
}

#hamburgerButton
{
    background-color: #e9ecef;
    margin: 0px;
    display: block;
    height: 35px;

    /*button fits size of ul when ul is open*/
    width: 100%;
}

#hamburgerList
{
    list-style-type: none;
    padding: 0%;
    margin: 0px;
    /* background-color: white; */
}

/*class selector styles*/

/* .contents
{
  background-color: #303030;
  color: #faf6f6;
} */

.floatinglist 
{
    float: left;
    margin: 0 20px;
}

.errortext{color: red;}

.intro
{
  text-align: center;
  /* background-color: white; */
  /* color: black; */
  /* background-color: #E9ECEF;
  padding: 2rem 1rem; */
}

.menu
{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /* background: white;
    width: 100%;
    border-style: outset; */
}

.menuItem
{
  /* margin: auto; */
  text-align: center;
  color: white;
  cursor: pointer;
  width: 100%;
  /* border-radius: 2px;
  border-color: white; */
}

.mobileMenuItem
{
  cursor: pointer;
  height: 35px;
  display: block;
  text-align: center;
  color: white;
}

.menuItem:after{display:block;}

.menuItem:hover:after{-webkit-transform: scaleX(1);transform: scaleX(1);}

.mobileMenuItem:after{display:block;}

.mobileMenuItem:hover:after{-webkit-transform: scaleX(1);transform: scaleX(1);}

.docked{position: fixed;}

.sticky{position: -webkit-sticky;position: sticky;}

.hidden{display: none;}

.allButFooter{min-height: calc(100vh - 95px);}

/* .allButFooter:not(.intro)
{
  background-color: black;
  color: white;
} */

.bi-linkedin{color: #0a66c2;}

.bi-github{color: purple;}

.hamburgerListItem
{
    text-align: center;
    border-bottom: 1px solid; 
}

.leftPad{padding-left: 5px;}

a:link,a:visited{text-decoration: none;}

footer
{
    text-align: center;
    font-size: .75rem;
}

img
{
    width: 150px;
    height: 200px;
    text-align: center;
}

/* body
{
  font-family: Arial, Helvetica, sans-serif;
  background-color: #1C2A44;
} */

header{height: 110vh;}

td input, td textarea{width: 100%;}

nav{height: 35px;}

.roundedCorners{border-radius: 2em;}

/*id selector styles*/

/* #menuitem0
{
    font-size: 24px;
} */

#contactTable
{
    margin-left: auto;
    margin-right: auto;
}

#divAboutDetails td
{
    border-style: solid;
    border-width: thin;
    width: auto;
}

#divMenuItems
{
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border: 0.1px solid #099FFF;
  background-color: #18181b;
  box-shadow: 0 4px 8px 0 #00FFFF, 0 6px 20px 0 rgba(0,0,0,0.19);
}

#divMobileMenuItems
{
  /* display: flex;
  flex-direction: row;
  padding: 10px 0; */
  border: 0.1px solid #099FFF;
  background-color: #18181b;
  box-shadow: 0 4px 8px 0 #00FFFF, 0 6px 20px 0 rgba(0,0,0,0.19);
}

#hamburgerButton
{
    background-color: #e9ecef;
    margin: 0px;
    display: block;
    height: 35px;

    /*button fits size of ul when ul is open*/
    width: 100%;
}

#hamburgerList
{
    list-style-type: none;
    padding: 0%;
    margin: 0px;
    /* background-color: white; */
}

/*class selector styles*/

/* .contents
{
  background-color: #303030;
  color: #faf6f6;
} */

.floatinglist 
{
    float: left;
    margin: 0 20px;
}

.errortext{color: red;}

.intro
{
  text-align: center;
  /* background-color: white; */
  /* color: black; */
  /* background-color: #E9ECEF;
  padding: 2rem 1rem; */
}

.menu
{
    position: sticky;
    top: 0;
    /* background: white;
    width: 100%;
    border-style: outset; */
}

.menuItem
{
  /* margin: auto; */
  text-align: center;
  color: white;
  cursor: pointer;
  width: 100%;
  /* border-radius: 2px;
  border-color: white; */
}

.mobileMenuItem
{
  cursor: pointer;
  height: 35px;
  display: block;
  text-align: center;
  color: white;
}

.menuItem:after{display:block;}

.menuItem:hover:after{transform: scaleX(1);}

.mobileMenuItem:after{display:block;}

.mobileMenuItem:hover:after{transform: scaleX(1);}

.docked{position: fixed;}

.sticky{position: sticky;}

.hidden{display: none;}

.allButFooter{min-height: calc(100vh - 95px);}

/* .allButFooter:not(.intro)
{
  background-color: black;
  color: white;
} */

.bi-linkedin{color: #0a66c2;}

.bi-github{color: purple;}

.hamburgerListItem
{
    text-align: center;
    border-bottom: 1px solid; 
}

.leftPad{padding-left: 5px;}